import { firebase, auth, db } from "Controller/firebase";

export const CourseController = {
  getId: () => {
    return auth.currentUser.uid;
  },
  subscribe: async (id, user) => {
    await db
      .collection("courses")
      .doc(id)
      .update({
        subscribe: firebase.firestore.FieldValue.arrayUnion(user),
      });
  },
  unsubscribe: async (id, user) => {
    await db
      .collection("courses")
      .doc(id)
      .update({
        subscribe: firebase.firestore.FieldValue.arrayRemove(user),
      });
  },
  getCourse: async (id) => {
    const course = (await db.collection("courses").doc(id).get()).data();
    const lessons = (
      await db.collection("courses").where("parent", "==", id).get()
    ).docs
      .map((doc) => ({ ...doc.data(), id: doc.id }))
      .filter((d) => !d?.type.includes("private") && !d?.type.includes("trash") && !d?.type.includes("remove"))
      .sort((a, b) => a.sort - b.sort);
    return { ...course, lessons };
  },
};
