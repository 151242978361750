import React, { useContext, useState, useEffect } from "react";
import { KGContext, MainContainer } from "@knowgistics/core";
import "@knowgistics/core/dist/index.css";
import { db } from "Controller/firebase";
import logo from "./chula_logo.svg";

import { connectKG } from "@knowgistics/core";
import { firebaseConfig } from "Controller/firebase";
import { BrowserRouter, Link, Route, Switch } from "react-router-dom";

import { PageScoresDetail } from "Pages/ScoresDetail";
import { PageCourseDetail } from "Pages/CourseDetail";
// import { PageHome } from "Pages/Home";
import { StudentNotApprovedPage } from "Pages/StudentNotApproved";
import "@knowgistics/core/dist/index.css";
import { PageQuestion } from "Pages/Question";
import { NameEdit } from "Components/NameEdit";
import { PageChatDetails } from "Pages/Chat";
import { VerifyEmail } from "Components/VerifyEmail";
import { PageMyCourse } from "Pages/MyCourse";
import { MyCourseAppBar } from "Components/MyCourse";
import { PageGradeDetails } from "Pages/Grade";

class ApprovedClass {
  constructor(user) {
    this.user = user;
  }
  get = (callback) => {
    return db
      .collection("students")
      .doc(this.user)
      .onSnapshot((snapshot) => {
        const docs = snapshot.data();
        callback(docs);
      });
  };
}

function App() {
  const { user, ...store } = useContext(KGContext);
  const [kgstate] = store.state;
  const [state, setState] = useState({
    fetched: false,
    docs: {},
  });

  useEffect(() => {
    document.title = `HOME | ${process.env.REACT_APP_SITE_NAME}`;
    if (kgstate.userFetched && user && user.uid) {
      const control = new ApprovedClass(user.uid);
      return control.get((docs) => {
        setState((s) => ({ ...s, docs, fetched: true }));
      });
    }
  }, [user, kgstate.userFetched]);

  if (!Boolean(state.fetched && kgstate.userFetched)) {
    return (
      <BrowserRouter>
        <MainContainer signInOnly loading={true} />
      </BrowserRouter>
    );
  } else {
    let contents = null;

    if (user && user?.emailVerified !== true) {
      contents = <VerifyEmail />;
    } else if (state?.docs) {
      contents = (
        <Switch>
          <Route
            path="/course/scores/details/:sid/chat/"
            component={(props) => (
              <PageChatDetails {...props} studentID={state?.docs?.studentID} />
            )}
          />
          <Route
            path="/course/scores/details/:sid/grade/"
            component={(props) => (
              <PageGradeDetails {...props} studentID={state?.docs?.studentID} />
            )}
          />
          <Route
            path="/course/scores/details/:sid/:tab?"
            component={PageScoresDetail}
          />
          <Route path="/course/:id/question/:lid" component={PageQuestion} />
          <Route path="/course/:id/lesson/:lid" component={PageCourseDetail} />
          <Route path="/course/:id" exact component={PageCourseDetail} />
          <Route path="/" exact component={PageMyCourse} />
        </Switch>
      );
    } else {
      contents = <StudentNotApprovedPage />;
    }
    return <BrowserRouter>{contents}</BrowserRouter>;
  }
}

export default connectKG({
  firebaseConfig,
  logo,
  Link,
  profileSecondaryActions: (
    <React.Fragment>
      <MyCourseAppBar />
      <NameEdit />
    </React.Fragment>
  ),
})(App);
