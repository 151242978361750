import { useCallback, useContext, useEffect } from "react";
import {
  Typography,
  withStyles,
  ListItem,
  ListItemText,
  Box,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Paper,
  ListItemIcon,
} from "@material-ui/core";
import {
  Paragraph,
  ImageDisplay,
  DuotoneButton,
  KGContext,
} from "@knowgistics/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faChevronLeft,
  faRedoAlt,
  faTimesCircle,
} from "@fortawesome/pro-duotone-svg-icons";
import { AnswerCheck } from "./Controller";
import { Link } from "react-router-dom";

const ListItemChoice = withStyles((theme) => ({
  root: {
    //borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
    border: "solid 2px " + theme.palette.success.main,
  },
}))(({ children, ...props }) => (
  <ListItem {...props}>
    <ListItemIcon>
      <FontAwesomeIcon icon={faCheckCircle} size="2x" color="white" />
    </ListItemIcon>
    {children}
  </ListItem>
));
const ListItemChoiceworng = withStyles((theme) => ({
  root: {
    //borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
    border: "solid 2px " + theme.palette.error.main,
  },
}))(({ children, ...props }) => (
  <ListItem {...props}>
    <ListItemIcon>
      <FontAwesomeIcon icon={faTimesCircle} size="2x" color="white" />
    </ListItemIcon>
    {children}
  </ListItem>
));

const ShowOption = ({ option }) => {
  if (!option) {
    return null;
  }

  switch (option.type) {
    case "paragraph":
      return <Paragraph value={option.value} />;
    case "image":
      return <ImageDisplay image={option.data} />;
    default:
      return null;
  }
};

export const CheckAnswer = ({ id, data, checked, ...props }) => {
  const { user } = useContext(KGContext);
  const calculate = useCallback(() => {
    if (Object.keys(checked).length === data.questions.length) {
      const score = data.questions.reduce((total, question) => {
        if (question.type === "truefalse") {
          if (checked[question.id] === question.answers) {
            total += 1;
          }
        } else if (question.type === "multiple") {
          if (checked[question.id] === question.answer) {
            total += 1;
          }
        } else if (question.type === "matching") {
          const answer = Object.assign(
            {},
            ...question.answers.map((val) => ({ [val.key]: val.value }))
          );
          const matched = Object.keys(checked[question.id]).filter(
            (key) => `${checked[question.id][key]}` === `${answer[key]}`
          );
          if (matched.length === Object.keys(answer).length) {
            total += 1;
          }
        } else if (question.type === "sorting") {
          const answers = question.answers.map((val) => val);
          const matcheds = Object.keys(checked[question.id]).filter(
            (key) => `${checked[question.id][key]}` === `${answers[key]}`
          );
          if (matcheds.length >= answers.length) {
            total += 1;
          }
        }
        return total;
      }, 0);
      return score;
    }
  }, [checked, data.questions]);

  useEffect(()=>{
    AnswerCheck.savePoints(id, data, calculate(), user.uid);
  },[data, calculate, user.uid, id])

  return (
    <>
      <Typography
        component="h1"
        variant="h2"
        align="center"
        color="textPrimary"
        gutterBottom
      >
        Total Score : {calculate()}/{data.questions.length}
      </Typography>
      {data.questions &&
        data.questions.map((val) => {
          let answer = null;
          switch (val.type) {
            case "truefalse":
              if (checked[val.id] === val.answers) {
                answer = (
                  <ListItemChoice>
                    <ListItemText style={{ lineHeight: 1.2 }} primary="True" />
                  </ListItemChoice>
                );
              } else {
                answer = (
                  <>
                    <ListItemChoiceworng>
                      <ListItemText
                        style={{ lineHeight: 1.2 }}
                        primary="False"
                      />
                    </ListItemChoiceworng>
                  </>
                );
              }
              break;
            case "multiple":
              if (checked[val.id] === val.answer) {
                const [rightOption] = val.options.filter(
                  (o) => o && `${o.key}` === `${val.answer}`
                );
                answer = (
                  <ListItemChoice>
                    <ListItemText style={{ lineHeight: 1.2 }}>
                      <ShowOption option={rightOption} />
                    </ListItemText>
                  </ListItemChoice>
                );
              } else {
                const [rightOption] = val.options.filter(
                  (o) => `${o.key}` === `${val.answer}`
                );
                const [wrongOption] = val.options.filter(
                  (o) => `${o.key}` === `${checked[val.id]}`
                );

                answer = (
                  <>
                    <ListItemChoiceworng>
                      <ListItemText style={{ lineHeight: 1.2 }}>
                        <ShowOption option={wrongOption} />
                      </ListItemText>
                    </ListItemChoiceworng>
                    <Box mt={1} />
                    <ListItemChoice>
                      <ListItemText style={{ lineHeight: 1.2 }}>
                        <ShowOption option={rightOption} />
                      </ListItemText>
                    </ListItemChoice>
                  </>
                );
              }
              break;
            case "matching":
              answer = (
                <Table>
                  <TableBody>
                    {checked[val.id] &&
                      val.answers &&
                      val.answers.map((ans) => (
                        <TableRow key={ans.key}>
                          <TableCell>
                            {(() => {
                              switch (ans.type) {
                                case "paragraph":
                                  return <Paragraph value={ans.label} />;
                                case "image":
                                  return <ImageDisplay image={ans.data} />;
                                default:
                                  return null;
                              }
                            })()}
                          </TableCell>
                          <TableCell>
                            {checked[val.id][ans.key] === ans.value ? (
                              <ListItemChoice>
                                <ListItemText style={{ lineHeight: 1.2 }}>
                                  {ans.value}
                                </ListItemText>
                              </ListItemChoice>
                            ) : (
                              <>
                                <ListItemChoiceworng>
                                  <ListItemText style={{ lineHeight: 1.2 }}>
                                    {checked[val.id][ans.key]}
                                  </ListItemText>
                                </ListItemChoiceworng>
                                <Box mt={1} />
                                <ListItemChoice>
                                  <ListItemText style={{ lineHeight: 1.2 }}>
                                    {ans.value}
                                  </ListItemText>
                                </ListItemChoice>
                              </>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              );
              break;
            case "sorting":
              const answers = val.answers.map((val) => val);
              const matcheds = Object.keys(checked[val.id]).filter(
                (key) => `${checked[val.id][key]}` === `${answers[key]}`
              );
              if (matcheds.length >= answers.length) {
                answer = (
                  <ListItemChoice>
                    <ListItemText
                      style={{ lineHeight: 1.2 }}
                      primary="เรียงลำดับถูกต้อง (TRUE)"
                    />
                  </ListItemChoice>
                );
              } else {
                answer = (
                  <>
                    <ListItemChoiceworng>
                      <ListItemText
                        style={{ lineHeight: 1.2 }}
                        primary="เรียงลำดับไม่ถูกต้อง (FALSE)"
                      />
                    </ListItemChoiceworng>
                  </>
                );
              }
              break;
            default:
              answer = null;
          }
          return (
            <Box mb={4} p={2} component={Paper} key={val.id}>
              <Typography variant="body1" component="div" paragraph>
                {val.qtype === "image" ? (
                  <>
                    <ImageDisplay image={val.question} />
                  </>
                ) : (
                  <Paragraph value={val.question} />
                )}
              </Typography>
              {answer}
            </Box>
          );
        })}

      <Box display="flex" justifyContent="center">
        <DuotoneButton
          icon={faChevronLeft}
          component={Link}
          to={`/course/${data.parent}`}
        >
          Back
        </DuotoneButton>
        &nbsp;
        <DuotoneButton
          variant="outlined"
          icon={faRedoAlt}
          onClick={async () => {
            window.location.reload();
          }}
        >
          {"Redo Quiz"}
        </DuotoneButton>
      </Box>
    </>
  );
};
