import { SkeletonController } from "@knowgistics/core";

export class MyCourseController extends SkeletonController {
  watch = (uid, callback) => {
    if (uid) {
      this.path("courses")
        .where("subscribe", "array-contains", uid)
        .onSnapshot((snap) => {
          const docs = snap.docs
            .map((doc) => ({ ...doc.data(), id: doc.id }))
            .filter(doc=>doc.type===`${process.env.REACT_APP_PREFIX}course`)
            .sort((a, b) =>
              a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase())
            );
          callback(docs);
        });
    } else {
      callback([]);
      return () => false;
    }
  };
  watchCourses = (callback) => {
    return this.path("courses")
      .where("type", "==", `${process.env.REACT_APP_PREFIX}course`)
      .onSnapshot((snap) => {
        const docs = snap.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        callback(docs);
      });
  };
  enroll = async (courseId, uid) => {
    return await this.path("courses", courseId).update({
      subscribe: this.arrayUnion(uid),
    });
  };
  unenroll = async (courseId, uid) => {
    if(!Boolean(courseId && uid)){
      throw new Error("Missing Parameter")
    }
    await this.path("courses", courseId).update({
      subscribe: this.firebase.firestore.FieldValue.arrayRemove(uid),
    });
    return true;
  };
}
