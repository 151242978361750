import { KGContext, ViewCourse } from "@knowgistics/core";
import { useContext } from "react";

export const PageCourseDetail = (props) => {
  const { t } = useContext(KGContext);
  const { id, lid = "home" } = props.match.params;

  return (
    <ViewCourse
      back={`/`}
      sitename={`${process.env.REACT_APP_SITE_NAME}`}
      id={id}
      selected={lid}
      baseURL={`/course/${id}`}
      breadcrumbs={[{ label: t("Home"), to: "/" }, { label: t("Lesson") }]}
    />
  );
};
