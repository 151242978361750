import React, { useContext } from "react";
import {
  Container,
  MainContainer,
  ContentHeader,
  ListDocs,
  KGContext,
} from "@knowgistics/core";
import { Sidebar } from "./Sidebar";
import { db } from "Controller/firebase";
import { useState, useEffect } from "react";
import {
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from "@material-ui/core";
import moment from "moment";
import "moment/locale/th";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-duotone-svg-icons";

class ScoresClass {
  constructor(sid, uid) {
    this.sid = sid;
    this.uid = uid;
  }
  get = (callback) => {
    return db
      .collection("scores")
      .where("type", "==", "quiz-score")
      .where("courseId", "==", this.sid)
      .where("userId", "==", this.uid)
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs
          .map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }))
          .sort((a, b) => {
            return b.date.toMillis() - a.date.toMillis();
          });
        callback(docs);
      });
  };
  getCourse = (callback) => {
    return db
      .collection("courses")
      .doc(this.sid)
      .onSnapshot((snap) => {
        callback({ ...snap.data() });
      });
  };
}

export const PageScoresDetails = (props) => {
  const { sid } = props.match.params;
  const { user } = useContext(KGContext);
  const [state, setState] = useState({
    fetched: false,
    docs: [],
    course: {},
  });

  useEffect(() => {
    document.title = `HOME | ${process.env.REACT_APP_SITE_NAME}`;
    const control = new ScoresClass(sid, user.uid);
    return control.get((docs) => {
      setState((s) => ({ ...s, docs, fetched: true }));
    });
  }, [sid, user.uid]);

  useEffect(() => {
    document.title = `HOME | ${process.env.REACT_APP_SITE_NAME}`;
    const control = new ScoresClass(sid);
    return control.getCourse((docs) => {
      setState((s) => ({ ...s, course: docs, fetched: true }));
    });
  }, [sid]);

  return (
    <MainContainer signInOnly sidebar={<Sidebar sid={sid} />}>
      <Container maxWidth="sm">
        <ContentHeader label={state.course.title} breadcrumbs={[{ label: 'Home', to: '/' }, { label: 'Course', to: `/course/${sid}` }]} />
        <ListDocs
          divider
          fetched={state.fetched}
          docs={state.docs}
          component={(doc, index) => (
            <ListItem divider dense key={doc.id}>
              <ListItemText
                primary={doc.title}
                secondary={<><FontAwesomeIcon icon={faClock} style={{marginRight: "0.5em"}} />{`${moment(
                  doc.date && doc.date.toMillis
                    ? doc.date.toMillis()
                    : Date.now()
                ).format("LL เวลา LT น.")}`}</>}
              />
              <ListItemSecondaryAction>
                {`${doc.score}/${doc.point} คะแนน`}
              </ListItemSecondaryAction>
            </ListItem>
          )}
        />
      </Container>
    </MainContainer>
  );
};
