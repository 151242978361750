import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/pro-duotone-svg-icons";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { KGContext } from "@knowgistics/core";
import { Link } from "react-router-dom";

export const MyCourseAppBar = (props) => {
  const { t } = useContext(KGContext);

  return (
    <ListItem button component={Link} to={`/`}>
      <ListItemIcon>
        <FontAwesomeIcon icon={faBook} />
      </ListItemIcon>
      <ListItemText primary={t("Courses.MyCourse")} />
    </ListItem>
  );
};
