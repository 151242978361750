import { SkeletonController } from "@knowgistics/core";
import axios from "axios";

export class GradingController extends SkeletonController {
  constructor(firebase, user, sid) {
    super(firebase);
    this.user = user;
    this.id = sid;
    this.prefix = process.env.REACT_APP_PREFIX;
  }

  get = async () => {
    const data = await axios.post(`${this.uploadURL}/student/grade`, {
      token: await this.user.getIdToken(),
      prefix: this.prefix,
      id: this.id,
    });
    return data.data;
  };
}