import React from "react";
import { Fragment } from "react";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserGraduate } from "@fortawesome/pro-duotone-svg-icons";
import { StudentInfo } from "Components/StudentInfo";

export const NameEdit = (props) => {
  const { t } = useTranslation();

  return (
    <Fragment>
      <StudentInfo>
        <ListItem button>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUserGraduate} />
          </ListItemIcon>
          <ListItemText primary={t("Users.Change")} />
        </ListItem>
      </StudentInfo>
    </Fragment>
  );
};
