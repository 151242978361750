import {
  faBook,
  faEllipsisV,
  faPlus,
} from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardContainer,
  Container,
  ContentHeader,
  DuotoneButton,
  MainContainer,
} from "@knowgistics/core";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import { CardMore } from "./card.more";
import { connectMC, injectMC } from "./ctx";
import { MyCourseLists } from "./lists";

export const PageMyCourse = connectMC(
  injectMC(
    ({
      store: {
        t,
        docs,
        open: [{ add }, setOpen],
        states: [, setState],
      },
      ...props
    }) => {
      const handleOpen = () => setOpen((o) => ({ ...o, add: !o.add }));
      const handleOpenMore =
        (id) =>
        ({ currentTarget }) => {
          setOpen((o) => ({ ...o, more: currentTarget }));
          setState((s) => ({ ...s, current: id }));
        };

      return (
        <MainContainer signInOnly>
          <Container maxWidth="lg">
            <ContentHeader
              label={t("Courses.MyCourse")}
              breadcrumbs={[{ label: t("Home") }]}
            />
            <CardContainer>
              {docs.map((doc) => (
                <Card
                  GridItemProps={{
                    xl: null,
                  }}
                  cover={doc?.cover}
                  primary={doc?.title}
                  secondary={doc?.desc}
                  tertiary={doc?.teacher}
                  key={doc?.id}
                  actions={
                    <DuotoneButton
                      icon={faBook}
                      color="primary"
                      component={Link}
                      to={`/course/${doc?.id}`}
                    >
                      {t("Study")}
                    </DuotoneButton>
                  }
                  more={
                    <IconButton
                      size="small"
                      color="inherit"
                      onClick={handleOpenMore(doc?.id)}
                    >
                      <FontAwesomeIcon icon={faEllipsisV} />
                    </IconButton>
                  }
                />
              ))}
              <Card
                GridItemProps={{
                  xl: null,
                }}
                button
                icon={<FontAwesomeIcon icon={faPlus} />}
                onClick={handleOpen}
              />
            </CardContainer>
          </Container>
          <MyCourseLists />
          <CardMore />
        </MainContainer>
      );
    }
  )
);
