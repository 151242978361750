import React, { useState, useEffect } from "react";
import { Chat } from "@knowgistics/core";
import { CourseController } from "Controller/courseController";

export const PageChatDetails = ({ studentID, ...props }) => {
  const { sid } = props.match.params;

  const [data, setData] = useState(null);

  useEffect(() => {
    if (sid) {
      CourseController.getCourse(sid).then((doc) => setData(doc));
      return () => false;
    }
  }, [sid]);

  return data ? (
    <Chat
      back={`/course/${sid}`}
      contacts={[{ uid: sid, displayName: data?.title ?? "" }]}
      baseURL={`/course/scores/details/${sid}/chat`}
      as={studentID}
      view={sid}
    />
  ) : null;
};
