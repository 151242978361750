import React, { useContext, useState, useEffect } from "react";
import {
  KGContext,
  MainContainer,
  Container,
  ContentHeader,
} from "@knowgistics/core";
import { Sidebar } from "./Sidebar";
import { GradingController } from "./ctl";
import { firebase } from "Controller/firebase";
import { DataGrid } from "@mui/x-data-grid";
import { Typography, Box } from "@material-ui/core";

export const PageGradeDetails = ({ studentID, ...props }) => {
  const { sid } = props.match.params;
  const { user } = useContext(KGContext);

  const [state, setState] = useState({
    fetched: false,
    docs: null,
  });

  useEffect(() => {
    if (sid && user) {
      const control = new GradingController(firebase, user, sid);
      control.get().then((docs) => {
        setState((s) => ({ ...s, docs, fetched: true }));
      });
      return () => {
        setState((s) => ({ ...s, fetched: true, docs: null }));
      };
    }
  }, [sid, user]);

  return (
    <MainContainer signInOnly sidebar={<Sidebar sid={sid} />}>
      <Container maxWidth="sm">
        <ContentHeader
          label="เกรด และคะแนนที่ได้"
          breadcrumbs={[
            { label: "Home", to: "/" },
            { label: "Course", to: `/course/${sid}` },
          ]}
        />
          <DataGrid
            autoHeight
            loading={!state?.fetched}
            rows={state?.docs?.weighted ?? []}
            // .concat([{id: 'grade',  title: 'เกรดเฉลี่ยสะสม', weighted: state.docs.grade}])
            columns={[
              {
                field: "title",
                headerName: "รายชื่อแบบทดสอบ",
                width: 550,
                editable: false,
              },
              {
                field: "weighted",
                headerName: "คะแนน",
                type: "number",
                width: 140,
                editable: false,
              },
            ]}
            pageSize={5}
            rowsPerPageOptions={[5]}
          />
        <Box display="flex" justifyContent="space-between" mt={2} >
        <Typography variant="h6">เกรดเฉลี่ยสะสม</Typography>
        <Typography variant="h6">{state?.docs?.grade ?? "F"}</Typography>
        </Box>
      </Container>
    </MainContainer>
  );
};
