import React, { useContext, useEffect, useState } from "react";
import { PageQuestionDetail } from "Pages/QuestionDetail";
import { KGContext } from "@knowgistics/core";
import { db } from "Controller/firebase";

class ScoresClass {
  constructor(lid, uid) {
    this.lid = lid;
    this.uid = uid;
  }
  getNum = (callback) => {
    return db
      .collection("scores")
      .where("type", "==", "quiz-score")
      .where("questionId", "==", this.lid)
      .where("userId", "==", this.uid)
      .onSnapshot((snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        callback(docs);
      });
  };
  // get = (callback) => {
  //   return db
  //     .collection("courses")
  //     .doc(this.lid)
  //     .onSnapshot((snap) => {
  //       callback({ ...snap.data() });
  //     });
  // };
}

export const PageQuestion = (props) => {
  const { user } = useContext(KGContext);
  const { lid } = props.match.params;
  const [state, setState] = useState({
    fetched: false,
    docs: {},
    scores: [],
  });

  // console.log(state.scores.length)

  // console.log(state.docs)

  // useEffect(() => {
  //   const control = new ScoresClass(lid);
  //   return control.get((docs) => {
  //     setState((s) => ({ ...s, docs, fetched: true }));
  //   });
  // }, [lid]);

  useEffect(() => {
    const control = new ScoresClass(lid, user.uid);
    return control.getNum((scores) => {
      setState((s) => ({ ...s, scores, fetched: true }));
    });
  }, [lid, user.uid]);

  return (
    <PageQuestionDetail
      id={lid}
      // fixnumber={state.docs.fixnumber}
      scoreLength={state.scores.length}
    />
  );

  // return <>{JSON.stringify(state.docs.fixnumber)}</>;
};
