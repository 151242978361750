import { KGContext, SkeletonController } from "@knowgistics/core";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";

class Controller extends SkeletonController {
  getFromDisplayName = (user) => {
    const sp = user?.displayName?.split?.(" ") ?? [];
    let data = {};
    if (sp.length === 1) {
      data.firstname = sp[0];
    } else if (sp.length === 2) {
      const [firstname, lastname] = sp;
      data = { firstname, lastname };
    } else if (sp.length === 3) {
      const [title, firstname, lastname] = sp;
      data = { title, firstname, lastname };
    } else if (sp.length === 4) {
      const [title, firstname, middlename, lastname] = sp;
      data = { title, firstname, middlename, lastname };
    }
    return data;
  };
  get = async (user) => {
    const snap = await this.path("students", user?.uid).get();
    if (snap.exists) {
      return snap.data();
    } else {
      return this.getFromDisplayName(user);
    }
  };
  update = async (uid, data) => {
    return await this.setupdate(this.path("students", uid), data, data);
  };
}

const useStyles = makeStyles((theme) => ({
  form: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const titles = ["นาย", "นางสาว", "นาง"];
const fields = [
  { label: "ชื่อ", key: "firstname", required: true },
  { label: "ชื่อกลาง (ถ้ามี)", key: "middlename" },
  { label: "ชื่อสกุล", key: "lastname", required: true },
  { label: "รหัสนักศึกษา", key: "studentID", required: true },
];
const defaultState = {
  title: "นาย",
  firstname: "",
  middlename: "",
  lastname: "",
  prefix: process.env.REACT_APP_PREFIX,
};
export const StudentInfo = (props) => {
  const classes = useStyles();
  const { user, t } = useContext(KGContext);
  const [open, setOpen] = useState(false);
  const [info, setInfo] = useState({ ...defaultState });
  const isComplete =
    fields.filter((d) => d.required && !info[d.key]).length === 0;
  const [control] = useState(new Controller(firebase));

  const handleOpen = (o) => () => setOpen(o);
  const handleChange = (key) => (e) =>
    setInfo((s) => ({ ...s, [key]: e.target.value }));
  const handleSave = async () => {
    if (isComplete && user && user.uid) {
      const data = {
        ...info,
        studentName: `${info.title} ${info.firstname}${
          info.middlename ? "" + info.middlename + "" : " "
        }${info.lastname}`,
        uid: user?.uid,
      };
      await user.updateProfile({ displayName: data.studentName });
      await control.update(user?.uid, data);
      window.location.reload();
    }
  };

  useEffect(() => {
    if (user) {
      control
        .get(user)
        .then((result) => setInfo({ ...defaultState, ...result }));
    }
  }, [user, control]);

  return (
    <React.Fragment>
      {props?.children &&
        React.cloneElement(props?.children, {
          onClick: handleOpen(true),
        })}
      <Dialog fullWidth maxWidth="xs" open={open} onClose={handleOpen(false)}>
        <DialogTitle>Student Information</DialogTitle>
        <DialogContent>
          <Box py={2}>
            <FormControl className={classes.form} fullWidth variant="outlined">
              <InputLabel id="title-label">คำนำหน้า</InputLabel>
              <Select
                labelId="title-label"
                labelWidth={72}
                value={titles.includes(info.title) ? info.title : "อื่นๆ"}
                onChange={handleChange("title")}
              >
                {titles.map((val) => (
                  <MenuItem value={val} key={val}>
                    {val}
                  </MenuItem>
                ))}
                <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
              </Select>
            </FormControl>
            {!titles.includes(info.title) && (
              <TextField
                className={classes.form}
                label="คำนำหน้าอื่นๆ"
                value={info.title === "อื่นๆ" ? "" : info.title}
                onChange={handleChange("title")}
              />
            )}
            {fields.map(({ key, label, ...props }) => (
              <TextField
                className={classes.form}
                key={key}
                label={label}
                value={info[key] || ""}
                onChange={handleChange(key)}
                {...props}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSave} disabled={!isComplete}>
            {t("Save")}
          </Button>
          <Button onClick={handleOpen(false)}>{t("Close")}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
