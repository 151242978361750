import { BackLink } from "@knowgistics/core";


import React from "react";

export const Sidebar = ({ sid, tab, ...props }) => {
  return (
    <>
      <BackLink to={`/course/${sid}`} />
      {/* <List disablePadding>
        {[{ label: "Grade", key: "grade" }].map((item, index) => (
          <ListItemLink
            button
            divider
            component={Link}
            key={item.key}
            to={`/course/scores/details/${sid}/${item.key}`}
            selected={tab === item.key || (index === 0 && !tab)}
          >
            <ListItemText primary={item.label} />
          </ListItemLink>
        ))}
      </List> */}
    </>
  );
};
