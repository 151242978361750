import { DialogRemove, ListDocs } from "@knowgistics/core";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { injectMC } from "./ctx";

export const MyCourseLists = injectMC(
  ({ store: { t, control, open:[{add},setOpen], user }, ...props }) => {
    const [state, setState] = useState({
      fetched: false,
      docs: [],
      remove: null,
    });

    const handleOpen = add => () => setOpen(o=>({ ...o, add }))
    const handleRemove = async () => {
      control.unenroll(state?.remove?.id, user?.data?.uid);
      setState((s) => ({ ...s, remove: null }));
    };

    useEffect(() => {
      return control?.watchCourses((docs) =>
        setState((s) => ({ ...s, fetched: true, docs }))
      );
    }, [control]);

    return (
      <React.Fragment>
        <Dialog fullWidth maxWidth="sm" open={add} onClose={handleOpen(false)}>
          <DialogTitle>{t("Course")}</DialogTitle>
          <DialogContent>
            <ListDocs
              divider
              fetched={state.fetched}
              docs={state.docs.sort((a, b) =>
                a?.title?.toLowerCase().localeCompare(b?.title?.toLowerCase())
              )}
              component={(doc, index) => (
                <ListItem key={doc?.id} divider button>
                  <ListItemAvatar>
                    <Avatar src={doc?.cover?.thumbnail} variant="square" />
                  </ListItemAvatar>
                  <ListItemText primary={doc?.title} secondary={doc?.teacher} />
                  <ListItemSecondaryAction>
                    {doc?.subscribe?.includes(user?.data?.uid) ? (
                      <Button
                        variant="outlined"
                        size="small"
                        color="secondary"
                        onClick={() => setState((s) => ({ ...s, remove: doc }))}
                      >
                        Unenroll
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                        onClick={() => control.enroll(doc?.id, user?.data?.uid)}
                      >
                        Enroll
                      </Button>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleOpen(false)}>{t("Close")}</Button>
          </DialogActions>
        </Dialog>
        <DialogRemove
          open={Boolean(state.remove)}
          title={t("Unenroll")}
          label={"Do you want to unenroll this course?"}
          onClose={() => setState((s) => ({ ...s, remove: null }))}
          onConfirm={handleRemove}
        />
      </React.Fragment>
    );
  }
);
