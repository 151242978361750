import { createContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";

export const GlobalContext = createContext({});

const defaultUser = {
  fetched: false,
  data: null,
  info: null,
};
export const GlobalProvider = ({ children, ...props }) => {
  const [user, setUser] = useState({ ...defaultUser });

  const store = {
    user,
  };

  useEffect(() => {
    return firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase
          .firestore()
          .collection("students")
          .doc(user?.uid)
          .get()
          .then((stuSnap) =>
            setUser((s) => ({
              fetched: true,
              data: user,
              info: stuSnap.data(),
            }))
          );
      } else {
        setUser((u) => ({ ...defaultUser, fetched: true }));
      }
    });
  }, []);

  return (
    <GlobalContext.Provider value={store}>{children}</GlobalContext.Provider>
  );
};
