import React from "react";
import { PageScoresDetails } from "./Scores";
import { Route, Switch } from "react-router-dom";

export const PageScoresDetail = (props) => {
  return (
    <Switch>
      <Route
        path="/course/scores/details/:sid/score"
        component={PageScoresDetails}
      />
    </Switch>
  );
};
