import { KGContext } from "@knowgistics/core";
import { MyCourseController } from "./ctl";
import { firebase } from "Controller/firebase";
import { GlobalContext } from "Controller/useGlobal";

const { createContext, useContext, useEffect, useState } = require("react");

const MCContext = createContext({});

const Provider = ({ children, ...props }) => {
  const { t, addAlert } = useContext(KGContext);
  const { user } = useContext(GlobalContext);
  const [docs,setDocs] = useState([])
  const [control,setControl] = useState(null)

  const store = {
    ...props,
    docs,
    open: useState({
      add: false,
      more: null,
    }),
    states: useState({
      current: null,
    }),
    addAlert,
    control,
    user,
    t,
  };

  useEffect(() => {
    if (user?.fetched && user?.info?.studentID) {
      const control = new MyCourseController(firebase);
      setControl(control)
      return control.watch(user?.data?.uid, setDocs);
    }
  }, [user]);

  return <MCContext.Provider value={store}>{children}</MCContext.Provider>;
};

export const connectMC = (Comp) => (props) =>
  <Provider {...props} children={<Comp />} />;

export const injectMC = (Comp) => (props) => {
  const store = useContext(MCContext);
  return <Comp {...props} store={store} />;
};
