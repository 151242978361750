import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Container,
  ContentHeader,
  isQuizComplete,
  KGContext,
  MainContainer,
  QuizDisplay,
  DuotoneButton,
} from "@knowgistics/core";
import { firebase } from "Controller/firebase";
import {
  Box,
  Button,
  Divider,
  LinearProgress,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Pagination } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-duotone-svg-icons";
import { CheckAnswer } from "./CheckAnswer";
import { Link } from "react-router-dom";

class Controller {
  constructor(id, user) {
    this.id = id;
    this.user = user;
  }
  get = async () => {
    const result = await firebase.functions().httpsCallable("ViewQuestion")({
      questionId: this.id,
      token: await this.user.getIdToken(),
    });
    return result.data;
  };
}

const Header = withStyles((theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius * 2,
    border: `solid 1px ${theme.palette.grey[300]}`,
  },
}))(Box);

const Progress = withStyles((theme) => ({
  root: {
    height: theme.spacing(1),
    borderRadius: theme.shape.borderRadius * 2,
  },
}))((props) => <LinearProgress variant="determinate" {...props} />);

export const PageQuestionDetail = ({
  id,
  // fixnumber,
  scoreLength,
  ...props
}) => {
  const { user, t } = useContext(KGContext);
  const [, setControl] = useState(null);
  const [state, setState] = useState({
    fetched: false,
    data: null,
    error: false,
    page: 1,
  });
  const [checked, setChecked] = useState({});
  const [answers, setAnswers] = React.useState(false);

  const getBank = (key) => state?.data?.[key] || null;
  const getCount = Boolean(state.data && state.data.questions)
    ? state.data.questions.length
    : 1;
  const progress = (state.page / getCount) * 100;

  const CheckScores = state?.data?.fixnumber
    ? parseInt(state?.data?.fixnumber ?? 0) > scoreLength
    : true;

  const handleChangePage = (e, page) => setState((s) => ({ ...s, page }));
  const handleChangeChecked = (key) => (n) =>
    setChecked((c) => ({ ...c, [key]: n }));
  const handleNext = (doc) => () => {
    if (isQuizComplete(doc, checked[doc.id])) {
      setState((s) => ({ ...s, page: s.page + 1 }));
    }
  };

  const NextButton = ({ doc }) => (
    <Button
      variant="contained"
      disableElevation
      color="primary"
      disabled={!isQuizComplete(doc, checked[doc.id])}
      onClick={handleNext(doc)}
      endIcon={<FontAwesomeIcon icon={faChevronRight} />}
    >
      NEXT
    </Button>
  );

  useEffect(() => {
    if (id && user) {
      const control = new Controller(id, user);
      setControl(control);
      control.get().then((data) => {
        if (data.error) {
          setState((s) => ({ ...s, fetched: true, error: data.error }));
        } else {
          document.title = `${data.title} | Quiz — ${process.env.REACT_APP_SITE_NAME}`;
          setState((s) => ({ ...s, data, fetched: true, error: false }));
        }
      });
    }
  }, [id, user]);

  return (
    <MainContainer loading={!state.fetched} signInOnly>
      <Container maxWidth="sm">
        {Boolean(answers) ? (
          <CheckAnswer id={id} data={state.data} checked={checked} />
        ) : (
          <>
            <ContentHeader
              label={getBank("title")}
              secondary={`แบบทดสอบนี้มีจำนวนทั้งหมด ${getCount} ข้อ`}
              actions={
                Boolean(answers) && (
                  <DuotoneButton variant="outlined">
                    {"Restart Quiz"}
                  </DuotoneButton>
                )
              }
            />
            {CheckScores ? (
              <Fragment>
                <Header mb={2}>
                  <Box p={2}>
                    <Typography color="primary" variant="caption">
                      {Math.ceil(progress)}% Complete
                    </Typography>
                    <Box mb={1} />
                    <Progress value={progress} />
                  </Box>
                  <Divider />
                  <Box display="flex" justifyContent="center" p={2}>
                    <Pagination
                      count={getCount}
                      color="primary"
                      page={state.page}
                      onChange={handleChangePage}
                      disabled
                    />
                  </Box>
                </Header>
                {state.data &&
                  Array.isArray(state.data.questions) &&
                  state.data.questions.map((doc, index) => (
                    <Box hidden={state.page !== index + 1} key={doc.id}>
                      <QuizDisplay
                        value={doc}
                        checked={checked[doc.id]}
                        onCheckChange={handleChangeChecked(doc.id)}
                      />
                      <Box display="flex">
                        <Box flex={1} />
                        {state.page >= getCount ? (
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setAnswers(true);
                            }}
                            disabled={!isQuizComplete(doc, checked[doc.id])}
                          >
                            submit
                          </Button>
                        ) : (
                          <NextButton doc={doc} />
                        )}
                      </Box>
                    </Box>
                  ))}
              </Fragment>
            ) : (
              <>
                {/* <ContentHeader label="คุณได้ทำแบบทดสอบครบจำนวนครั้งตามที่กำหนดไว้แล้ว" /> */}
                <Typography variant="h6" >
                  {t('Quiz maximum attempts reached',{times: state?.data?.fixnumber})}
                </Typography>
                <Box mb={3} />
                <DuotoneButton
                  icon={faChevronLeft}
                  component={Link}
                  to={`/course/${state.data && state.data.parent}`}
                >
                  Back
                </DuotoneButton>
              </>
            )}
          </>
        )}
      </Container>
    </MainContainer>
  );
};
