import { faEnvelopeOpen, faSignOut } from "@fortawesome/pro-duotone-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KGContext } from "@knowgistics/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  lighten,
  Slide,
  withStyles,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";
import { faSpinner } from "@fortawesome/pro-regular-svg-icons";

export const VerifyEmail = withStyles((theme) => ({
  root: {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  dialog: {
    boxShadow: "none",
  },
}))(({ classes: cs, ...props }) => {
  const { t, user } = useContext(KGContext);
  const [state, setState] = useState({
    time: null,
    lim: null,
  });

  const handleVerify = async () => {
    const lim = Date.now() + 60000;
    setState((s) => ({ ...s, lim }));
    await user.sendEmailVerification()
  };
  const handleSignOut = () => firebase.auth().signOut();

  useEffect(() => {
    const itv = setInterval(() => {
      setState((s) => ({ ...s, time: Date.now() }));
    }, 1000);
    return () => clearInterval(itv);
  }, []);

  return (
    <div className={cs.root}>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={true}
        hideBackdrop
        PaperProps={{ elevation: 0 }}
        TransitionComponent={Slide}
        TransitionProps={{ direction: "down" }}
      >
        <DialogTitle>Verify E-mail</DialogTitle>
        <DialogContent>
          {state?.lim > state?.time ? (
            <Button
              fullWidth
              variant="outlined"
              size="large"
              startIcon={<FontAwesomeIcon icon={faSpinner} pulse />}
              disabled
            >
              Please Wait {parseInt((state?.lim - state?.time) / 1000)}{" "}
              second(s)
            </Button>
          ) : (
            <Button
              fullWidth
              variant="outlined"
              size="large"
              startIcon={<FontAwesomeIcon icon={faEnvelopeOpen} />}
              onClick={handleVerify}
            >
              Verify E-mail
            </Button>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            color="secondary"
            variant="outlined"
            startIcon={<FontAwesomeIcon icon={faSignOut} />}
            onClick={handleSignOut}
          >
            {t("SignOut")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
