import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/auth';

export let firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId,
};

if( !firebase.apps.length ){
  firebase.initializeApp(firebaseConfig);
}

const db = firebase.firestore();
const dbTimestamp = firebase.firestore.FieldValue.serverTimestamp;
const auth = firebase.auth();
const storage = firebase.storage();

export {
  firebase,
  db,
  dbTimestamp,
  auth,
  storage,
};
