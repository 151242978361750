import { auth, db, dbTimestamp } from "Controller/firebase";

export const AnswerCheck = {
  getId: () => {
    return auth.currentUser.uid;
  },
  savePoints: async (id, data, score, uid) => {
    const datas = (await db.collection("students").doc(uid).get()).data();

    const addData = {
      title: data.title,
      courseId: data.parent,
      questionId: id,
      questionparentId: data.questionid,
      userId: uid,
      studentId: datas.studentID,
      point: data.point,
      amount: data.amount,
      score: score,
      type: "quiz-score",
      date: dbTimestamp(),
    };
    await db.collection("scores").add(addData);
  },
};
