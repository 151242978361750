import { DialogRemove } from "@knowgistics/core";
import { Menu, MenuItem, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { injectMC } from "./ctx";

export const CardMore = injectMC(
  ({
    store: {
      open: [{ more }, setOpen],
      states: [{ current }, setState],
      control,
      user,
      t,
    },
    ...props
  }) => {
    const [cf, setCf] = useState(false);

    const handleCF = () => {
      setCf(true);
    };
    const handleClose = () => {
      setOpen((o) => ({ ...o, more: null }));
      setState((s) => ({ ...s, current: null }));
    };
    const handleUnenroll = async () => {
      if (current && user?.data?.uid) {
        setOpen((o) => ({ ...o, more: null }));
        await control.unenroll(current, user?.data?.uid);
        setState((s) => ({ ...s, current: null }));
      }
      setCf(false);
    };

    return (
      <React.Fragment>
        <Menu open={Boolean(more)} anchorEl={more} onClose={handleClose}>
          {/* <MenuItem
            component={Link}
            to={`/course/scores/details/${current}/score`}
          >
            Score
          </MenuItem> */}
          <MenuItem
            component={Link}
            to={`/course/scores/details/${current}/grade`}
          >
            Score
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/course/scores/details/${current}/grade`}
          >
            Grade
          </MenuItem>
          <MenuItem
            component={Link}
            to={`/course/scores/details/${current}/chat`}
          >
            Chat
          </MenuItem>
          <MenuItem component={Typography} color="secondary" onClick={handleCF}>
            Unenroll
          </MenuItem>
        </Menu>
        <DialogRemove
          title={t("Unenroll")}
          label={t("Do you want to unenroll this course?")}
          open={cf}
          onClose={() => setCf(false)}
          onConfirm={handleUnenroll}
        />
      </React.Fragment>
    );
  }
);
