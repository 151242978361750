import { KGContext, MainContainer } from "@knowgistics/core";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { auth } from "Controller/firebase";
import { useContext, useEffect, useState } from "react";
import { firebase } from "Controller/firebase";
import { merge } from "lodash";

const useStyles = makeStyles((theme) => ({
  form: {
    "&:not(:last-child)": {
      marginBottom: theme.spacing(2),
    },
  },
}));

const titles = ["นาย", "นางสาว", "นาง"];
const fields = [
  { label: "ชื่อ", key: "firstname", required: true },
  { label: "ชื่อกลาง (ถ้ามี)", key: "middlename" },
  { label: "ชื่อสกุล", key: "lastname", required: true },
  { label: "รหัสนักศึกษา", key: "studentID", required: true },
];
const defaultState = {
  title: "นาย",
  firstname: "",
  middlename: "",
  lastname: "",
  prefix: process.env.REACT_APP_PREFIX,
};
export const StudentNotApprovedPage = (props) => {
  const classes = useStyles();
  const { user, t } = useContext(KGContext);
  const [info, setInfo] = useState({ ...defaultState });
  const isComplete =
    fields.filter((d) => d.required && !info[d.key]).length === 0;

  const handleSignOut = () => {
    auth.signOut();
    window.location.reload();
  };

  const handleChange = (key) => (e) =>
    setInfo((s) => ({ ...s, [key]: e.target.value }));
  const handleSave = async () => {
    if (isComplete && user && user.uid) {
      const id = await firebase
        .firestore()
        .collection("students")
        .doc(info.studentID)
        .get();
      let data = {
        ...info,
        studentName: `${info.title} ${info.firstname}${
          info.middlename ? "" + info.middlename + "" : " "
        }${info.lastname}`,
        uid: user.uid,
      };
      if (id.exists) {
        data = merge(id.data(), data);
        await firebase.firestore().collection("students").doc(info.studentID).delete();
      }
      await user.updateProfile({displayName: data.studentName});
      await firebase
        .firestore()
        .collection("students")
        .doc(user.uid)
        .set(data, { merge: true });
        window.location.reload();
    }
  };

  useEffect(() => {
    if (user && user.displayName) {
      const sp = user.displayName.trim().split(" ");
      if (sp.length === 1) {
        setInfo((s) => ({ ...s, firstname: sp[0] }));
      } else if (sp.length === 2) {
        setInfo((s) => ({ ...s, firstname: sp[0], lastname: sp[1] }));
      }
    }
  }, [user]);

  return (
    <MainContainer>
      <Dialog fullWidth maxWidth="xs" open={true}>
        <DialogTitle>Student Information</DialogTitle>
        <DialogContent>
          <Box py={2}>
            <FormControl className={classes.form} fullWidth variant="outlined">
              <InputLabel id="title-label">คำนำหน้า</InputLabel>
              <Select
                labelId="title-label"
                labelWidth={72}
                value={titles.includes(info.title) ? info.title : "อื่นๆ"}
                onChange={handleChange("title")}
              >
                {titles.map((val) => (
                  <MenuItem value={val} key={val}>
                    {val}
                  </MenuItem>
                ))}
                <MenuItem value="อื่นๆ">อื่นๆ</MenuItem>
              </Select>
            </FormControl>
            {!titles.includes(info.title) && (
              <TextField
                className={classes.form}
                label="คำนำหน้าอื่นๆ"
                value={info.title === "อื่นๆ" ? "" : info.title}
                onChange={handleChange("title")}
              />
            )}
            {fields.map(({ key, label, ...props }) => (
              <TextField
                className={classes.form}
                key={key}
                label={label}
                value={info[key] || ""}
                onChange={handleChange(key)}
                {...props}
              />
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleSave} disabled={!isComplete}>
            {t("Save")}
          </Button>
          <Button color="secondary" onClick={handleSignOut}>
            {t("SignOut")}
          </Button>
        </DialogActions>
      </Dialog>
    </MainContainer>
  );
};
